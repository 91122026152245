'use strict'

###*
 # @ngdoc service
 # @name mundoData.factory:SessionData

 # @description

###
angular
  .module 'mundoData'
  .factory 'SessionData', [
    'Restangular'
    (Restangular) ->
      SessionDataBase = Restangular
        .service('sessions')

      SessionDataBase
  ]
